@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap");
html {
    scroll-behavior: smooth;
}
body {
    background-color: rgb(11,25,47);
    margin: 0;
    color: rgb(171,182,212);
    font-family: "Raleway", serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    line-height: 2;
}
.green {
    color: rgb(99,255,217);
}

